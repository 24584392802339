import React from 'react'
import { ConfirmationContainer } from 'tf-checkout-react'

import Layout from '../../components/layout'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import Head from '../../components/head/index'
import './checkout-complete.scss'

setTfCheckoutReactConfigs()
const CheckoutCompletePage = () => (
  <>
    <Head title='Confirmation Page' />
    <Layout>
      <div className='checkout-complete-container'>
        <ConfirmationContainer
          referralPromotions={[]}
          shareButtons={[]}
          shareLink={''}
          isReferralEnabled={true}
          showDefaultShareButtons={true}
          hasCopyIcon={false}
        />
      </div>
    </Layout>
  </>
)

export default CheckoutCompletePage
